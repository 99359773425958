/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import ReactSelect from 'react-select';
import Creatable from 'react-select/creatable';

// import './react-select.css';

function SelectCanBeCreatable(props) {
  const creatable = props.creatable;
  if (creatable) {
    return <Creatable {...props} />;
  }
  return <ReactSelect {...props} />;
}

SelectCanBeCreatable.propTypes = {
  creatable: PropTypes.bool
};

const Select = ({
  input: { value, onChange, name }, placeholderTitle, data, options, multi, clearable,
  meta: { touched, error }, onCreateNewValue, onChangeHandler, disabled }) => {

    const selectOptions = options?.map((opt) => opt.DisplayName ? (
      { label: opt.DisplayName, value: opt.value }
    ) : (
      { label: opt.label, value: opt.value }
    ));
  
  let selectValue;
  if (selectOptions) {
    if (Array.isArray(value)) {
      selectValue = selectOptions.filter((option) => value.indexOf(option.value) !== -1);
    } else if (value !== '') {
      selectValue = selectOptions.find((option) => option.value == value);
    }
  }

  return (
    <div className="tooltip-cont">
      <SelectCanBeCreatable
        closeMenuOnSelect={!multi}
        isDisabled={disabled}
        backspaceRemovesValue={clearable}
        value={value != null ? selectValue : ''}
        placeholder={placeholderTitle}
        data={data}
        menuPlacement='auto'
        menuPosition='absolute'
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuShouldScrollIntoView={false}
        menuPortalTarget={document.body}
        isMulti={multi}
        onChange={
          (event) => {
            var values = [];
            if (Array.isArray(event)) {
              event.forEach((val) => {
                values.push(val.value);
              });
              onChange(values);
              if (onChangeHandler != null) {
                onChangeHandler(values);
              }
            }
            else {
              const eventValue = event ? event.value : '';
              onChange(eventValue);
              if (onChangeHandler != null) {
                onChangeHandler(eventValue);
              }
            }
            if (event && event.__isNew__) {
              onCreateNewValue(name, event.label);
            }
          }
        }
        options={selectOptions} // <-- Receive options from the form
        // {...this.props}
      />
      {touched && error && <span className="tooltip">{error}</span>}
    </div>
  );
}

export default Select;
