import { en } from './en';
import { fr } from './fr';
import { it } from './it';
import { he } from './he';
import { es } from './es';

export const translations = {
    en,
    fr,
    it,
    he,
    es
};
