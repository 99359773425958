import { connect } from 'react-redux';

import {setMapDrawMode} from "../../actions/setters";
import * as actionsSensors from '../../actions/SensorsActions';
import ActualInstallationPointLocationWindow from '../../components/Custom/ActualInstallaionPointLocationWindow';

const Coordinates = require('coordinate-parser');

const mapStateToProps = (state, ownProps) => {
  const actualInstallationPointPositionState = state.temp.actualInstallationPoint;

  return Object.assign({}, {
    actualInstallationPointPositionState
  }, ownProps);
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (values, dispatch) => {
      const coordinate = new Coordinates(values.Coordinate);
      const data = [
        {
          field: 'FixLatitude',
          value: coordinate.getLatitude(),
          type: 'Float'
        },
        {
          field: 'FixLongitude',
          value: coordinate.getLongitude(),
          type: 'Float'
        }
      ];

      dispatch(actionsSensors.setInstallationPointPosition(data));
      dispatch(actionsSensors.openActualInstallationPointLocation(false));
      dispatch(setMapDrawMode(false));
    },

    close: () => {
      dispatch(actionsSensors.openActualInstallationPointLocation(false));
      dispatch(setMapDrawMode(false));
    }
  };
};

const CActualInstallationPointWindow = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActualInstallationPointLocationWindow);

export default CActualInstallationPointWindow;
