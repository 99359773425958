import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../../Modals/AppModal';

import { Field, reduxForm } from 'redux-form';
import { ENV, ENVIRONMENTS } from '../../../constants/EndPoints';
import ImagesTab from '../../AlertsTabs/ImagesTab/ImagesTab';
import Input from '../../Input/Input';
import AddressField from '../../Custom/AddressField';
import CommentField from '../../CommentField/CommentField';

import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

require('../../SampleInfo/SampleInfo.scss');

class SensorTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textDir: "ltr",
    };

    this.openGallery = this.openGallery.bind(this);
    this.closeGallery = this.closeGallery.bind(this);
    this.changeTextDir = this.changeTextDir.bind(this);
    this.openActualInstallationPointLocation = this.openActualInstallationPointLocation.bind(this);
  }

  changeTextDir(dir) {
    this.setState({
      textDir: dir,
    });
  }

  openActualInstallationPointLocation() {
    this.props.openActualInstallationPointLocation();
  }
  openGallery() {
    this.setState({
      galleryOpen: true
    });
  }

  closeGallery() {
    this.setState({
      galleryOpen: false
    });
  }

  render() {
    const { selectedFeature, error, handleSubmit, pristine, reset, submitting, user, CSensorTab, timeZone, fileDetails, downloadFile, images } = this.props;
    const audioFile = fileDetails ? fileDetails.fileName : false;
    const audioSampleTime = fileDetails ? fileDetails.sampleTime : false;
    const originalFileName = (fileDetails != null && fileDetails.originalFileName != null) ? fileDetails.originalFileName : false;

    const labelsClass = 'col-xs-4 control-label';
    const fieldsClass = 'col-xs-8 control-input';
    const divStyle = { float: 'left', width: '100%' };

    return (
      <div>
        <form
          className='form-horizontal sticky-actions'
          onSubmit={handleSubmit}
        >
          <CommentField
            fieldName='MaintenanceComment'
            featureID={selectedFeature}
            placeholder={this.context.t('sensor_comment')}
            onEdit={this.props.onEdit}
            onCopy={this.props.onCopy}
            disabled={!user.editMode}
          />

          <div style={{ display: 'flex' }}>
            <div style={divStyle}>
              <AddressField
                className='form-group'
                user={this.props.user}
                fieldName='StreetAddress'
                onGetAddress={this.props.getAddressByLatLong}
              />
              <div className='form-group'>
                <label className={labelsClass}>
                  {this.context.t('asset_id')}:
                </label>
                <div className={fieldsClass}>
                  <Field
                    name='AssetID'
                    component={Input}
                    type='text'
                    disabled
                    className='form-control'
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className={labelsClass}>
                  {this.context.t('first_installation_date')}:
                </label>
                <div className={fieldsClass}>
                  <Field
                    name='SensorInstallationDate'
                    component={Input}
                    type='date'
                    className='form-control'
                    disabled={!user.editMode}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className={labelsClass}>
                  {this.context.t('last_installation_date')}:
                </label>
                <div className={fieldsClass}>
                  <Field
                    name='DeviceInstallationDate'
                    component={Input}
                    type='date'
                    className='form-control'
                    disabled={!user.editMode}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className={labelsClass}>
                  {this.context.t('last_communication')}:
                </label>
                <div className={fieldsClass}>
                  <Field
                    name='LastCommunicationTime'
                    component={Input}
                    type='date'
                    className='form-control'
                    disabled
                  />
                </div>
              </div>
              {this.props.user.advancedMapIndications &&
                this.props.initialValues &&
                this.props.initialValues.IsPressure && (
                  <div className='form-group'>
                    <label className={labelsClass}>
                      {this.context.t('prs_calib_multiply')}:
                    </label>
                    <div className={fieldsClass}>
                      <Field
                        name='pressure_calib_coeff'
                        component={Input}
                        type='number'
                        className='form-control'
                      />
                    </div>
                  </div>
                )}
            </div>

            <div style={divStyle}>
              <div className='form-group'>
                <label className={labelsClass}>
                  {this.context.t('sample_time_3')}:
                </label>
                <div className={fieldsClass}>
                  <Field
                    name='SampleTime1Millis'
                    component={Input}
                    className='form-control'
                    type='time'
                    timeZone={timeZone}
                    disabled
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className={labelsClass}>
                  {this.context.t('sample_time_2')}:
                </label>
                <div className={fieldsClass}>
                  <Field
                    name='SampleTime2Millis'
                    component={Input}
                    className='form-control'
                    type='time'
                    timeZone={timeZone}
                    disabled
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className={labelsClass}>
                  {this.context.t('sample_time_1')}:
                </label>
                <div className={fieldsClass}>
                  <Field
                    name='SampleTime3Millis'
                    component={Input}
                    className='form-control'
                    type='time'
                    timeZone={timeZone}
                    disabled
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className={labelsClass}>
                  {this.context.t('config_duration')}:
                </label>
                <div className={fieldsClass}>
                  <Field
                    name='ConfigRecordingTimeStr'
                    component={Input}
                    className='form-control'
                    type='text'
                    disabled
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className={labelsClass}>
                  {this.context.t('radio_frequency')}:
                </label>
                <div className={fieldsClass}>
                  <Field
                    name='RadioFrequency'
                    component={Input}
                    className='form-control'
                    type='text'
                    disabled
                  />
                </div>
              </div>

              {this.props.user.advancedMapIndications &&
                this.props.initialValues &&
                this.props.initialValues.IsPressure && (
                  <div className='form-group'>
                    <label className={labelsClass}>
                      {this.context.t('prs_calib_shift')}:
                    </label>
                    <div className={fieldsClass}>
                      <Field
                        name='pressure_calib_coeff_y_axis'
                        component={Input}
                        type='number'
                        className='form-control'
                      />
                    </div>
                  </div>
                )}
            </div>
          </div>

          <div className='form-actions text-right'>
            <button
              className='btn btn-success btn-submit'
              disabled={pristine || submitting}
              type='submit'
            >
              {this.context.t('save')}
            </button>
            {JSON.parse(process.env.REACT_APP_FILES) && (
              <i
                className='btn btn-success btn-order icon-gallery'
                disabled={images == null || images.length == 0}
                onClick={this.openGallery}
              />
            )}
            <div className='form-feedback text-danger'>{error}</div>
            { user.isAQS &&
              <a
                className='btn btn-warning btn-order'
                onClick={this.openActualInstallationPointLocation}
              >
                {this.context.t('set_actual_installation_point_position')}
              </a>
            }



            {audioFile && audioSampleTime && (
              <a
                key={audioFile}
                className='btn-wav-download'
                onClick={() => {
                  downloadFile(audioFile, 'wav');
                }}
                href='javascript:void(0);'
              >
                {this.context.t('download_sensor_audio')}
                <span style={{ marginLeft: '10px' }}>
                  {' '}
                  [
                  {UIDateFormater(
                    audioSampleTime,
                    DateType.DATE_AND_TIME,
                    timeZone
                  )}
                  ]
                </span>
              </a>
            )}
            {originalFileName && (
              <a
                key={originalFileName}
                className='btn-wav-download'
                onClick={() => {
                  downloadFile(originalFileName, 'wav');
                }}
                href='javascript:void(0);'
              >
                {this.context.t('original_audio')}
              </a>
            )}
          </div>
        </form>

        {JSON.parse(process.env.REACT_APP_FILES) && (
          <AppModal
            closeIcon
            open={this.state.galleryOpen}
            onClose={this.closeGallery}
            content={<ImagesTab images={images} />}
          />
        )}
      </div>
    );
  }
}

SensorTab.contextTypes = {
  t: PropTypes.func.isRequired
};

// Decorate the form component
const sensorTab = reduxForm({
  form: 'sensor', // a unique name for this form
  enableReinitialize: true
})(SensorTab);

export default sensorTab;
